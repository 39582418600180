.app {
    background-color: var(--primary-color);
}
  
.app__whitebg {
    background-color: var(--white-color);
}

.app__primarybg {
    background-color: var(--primary-color);
}

.app__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1300px;
    margin: auto;
    padding: 2rem 1rem;
    background-color: var(--white-color);

    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 1rem;
    }
}

.app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
    line-height: 1.5;
}
