.app__button-products {
    position: relative;
    padding: 1rem 0; 

    img {
        width: 1629px;
        height: 150px;
        max-inline-size: 100%;
        padding: 1rem;
        border-radius: 40px;
        object-fit: cover;
        filter: brightness(50%);
    }

    h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.9);
        border: 1px;
        border-style: solid;
        border-color: var(--secondary-color);
        border-radius: 15px;
        padding-right: 10px;
        padding-left: 10px;
        text-decoration: none;
        text-transform: uppercase;
        color: var(--black-color);
    }
}