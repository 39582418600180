.app__footer {
    background-color: var(--bg-dark);
    font-family: var(--font-base);
}

.app__footer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 1rem;
    color: var(--white-color);
}

.app__footer-social {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;

    a {
        padding: 0.5rem 0.8rem;
        margin: 0.5rem 0.5rem;
        color: var(--white-color);
        text-decoration: none;
        border: 1px;
        border-color: var(--white-color);
        border-style: solid;
        border-radius: 30px;
        transition: all 0.1s ease-in-out;

        &:hover {
            color: var(--bg-dark);
            background-color: var(--white-color);
        }
    }
}

.app__footer-grid {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;

    div {
        padding: 1rem 1rem;
    }

    strong {
        font-weight: 500;
        font-size: 20px;
    }
}

.app__footer-useful-links {
    li {
        list-style-type: none;

        a {
            color: var(--white-color);
            text-decoration: none;
            transition: all 0.1s ease-in-out;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.app__footer-useful-info {
    display: flex;
    flex-direction: column;

    li {
        list-style-type: none;

        a {
            text-decoration: none;
            color: var(--secondary-color);
            transform: all 0.1s ease-in-out;

            &:hover {
                text-decoration: underline;
            }
        }
    }

}

.app__footer-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.2);
    padding-top: 1rem;
    padding-bottom: 2rem;

    li {
        list-style-type: none;

        a {
            text-decoration: none;
            color: var(--secondary-color);
            transform: all 0.1s ease-in-out;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}