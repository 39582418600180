.app__product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    hr {
        width: 100%;
    }

    button {
        padding: 0.1rem 0.5rem;
        border-radius: 20px;
        border-color: var(--secondary-color);
        border-style: solid;
        background-color: var(--white-color);

        a {
            color: var(--black-color);
            text-decoration: none;
            transition: all 0.1s ease-in-out;

            &:hover {
                color: var(--secondary-color);
            }
        }
    }
}

.app__product-cards {
    padding: 1rem;
}
.app__product-description {
    padding: 1rem;
}

.app__product-card {
    width: 100%;

    button {
        padding: 0.3rem 0.3rem;
        margin: 0.5rem;
        border-radius: 20px;
        border-color: var(--secondary-color);
        border-style: solid;
        background-color: var(--white-color);
        color: var(--black-color);
        text-decoration: none;
        transition: all 0.1s ease-in-out;

        &:hover {
            color: var(--secondary-color);
        }
    }
}