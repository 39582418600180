.app__quote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    hr {
        width: 90%;
        background-color: var(--black-color);
    }
}

.app__quote-description {
    padding: 1rem;
}

.app__quote-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    background-color: var(--primary-color);
    border: 3px;
    border-style: solid;
    border-color: var(--secondary-color);
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 1.5rem;
    font-family: var(--font-base);

    div {
        width: 80%;
        margin: 0.5rem 0;
        border-radius: 10px;
        padding: 0.5rem 0;

        input, textarea {
            width: 100%;
            padding: 0.70rem;
            border-radius: 20px;
            border: none;
            border-color: var(--secondary-color);
            background-color: var(--white-color);
            color: var(--secondary-color);
            font-family: var(--font-base);
            text-decoration: none;
            outline: none;
            cursor: pointer;
            transition: all 0.05s ease-in-out;

            &:hover {
                outline: 2px solid;
            }

            &:active {
                outline: 2px solid;
            }
        }

        textarea {
            height: 170px;
        }
    }

    button {
        padding: 1rem;
        border-radius: 6px;
        border-color: var(--secondary-color);
        border-style: solid;
        background-color: var(--secondary-color);
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        color: var(--white-color);
        transition: all 0.1s ease-in-out;

        a {
            text-decoration: none;
            color: var(--white-color);
            transition: all 0.1s ease-in-out;
        }

        &:hover {
            background-color: var(--white-color);
            color: var(--secondary-color);

            a {
                color: var(--secondary-color);
                border-bottom-style: none
            }
        }
    }

    span {
        display: none;
        color: red;
    }

    input:invalid[focused="true"] ~ span {
        display: block;
    }

    textarea:invalid[focused="true"] ~ span {
        display: block;
    }
}

.app__quote-recaptcha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;

    div {
        all: unset; //Remove all the component default style
    }

    @media screen and (max-width: 450px) {
        transform: scale(0.8);
    }
}