.app__news-shade {
    display: block;
    position: fixed;
    width: 10000px;
    height: 10000px;
    top: 0%;
    background-color: rgba(0,0,0, 0.5);
    z-index: 5;
}

.app__news-inner {
    position: fixed;
    width: 80%;
    top: 40%;
    margin-left: 10%;
    margin-right: 10%;
    padding: 1rem;
    border-radius: 20px;
    background-color: var(--white-color);
    color: var(--secondary-color);
    border: 1px;
    border-style: solid;
    border-color: var(--secondary-color);
    text-align: center;
    z-index: 4;
}

.app__news-title {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: x-large;
    font-weight: 700;
    border-bottom: 1px;
    border-bottom-color: var(--secondary-color);
    border-bottom-style: solid;
}

.app__news-button {
    margin-top: 1rem;
    margin-bottom: 3rem;

    button {
        padding: 0.5rem 0.7rem;
        border-radius: 20px;
        border-color: var(--secondary-color);
        border-style: solid;
        background-color: var(--white-color);

        &:hover {
            color: var(--secondary-color);
        }

        a {
            color: var(--black-color);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.1s ease-in-out;

            
        }
    }
}

.singlenews {
    margin-bottom: 0;
}

.app__news-openbutton {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 4;

    button {
        border: 3px;
        border-style: solid;
        border-color: var(--secondary-color);
        border-radius: 600px;
        outline: none;
        background-color: var(--white-color);
        cursor: pointer;
        padding: 10px;
    }
}