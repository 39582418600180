@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap');

:root {
  --font-base: "Arimo", sans-serif;

  --primary-color: #d665091a;
  --secondary-color: #d66509;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --bg-dark: #23282c;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

footer {
  background-color: #1C2023;
  text-align: center;
}

footer a {
  color: var(--secondary-color);
  text-decoration: none;
  transform: all 0.1s ease-in-out;
}

footer a:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}