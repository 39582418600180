.app__topbutton {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 4;

    button {
        border: 3px;
        border-style: solid;
        border-color: var(--secondary-color);
        border-radius: 300px;
        outline: none;
        background-color: var(--white-color);
        cursor: pointer;
        padding: 10px;
    }
}