.app__nopage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    p {
        padding: 1rem;
    }

    button {
        padding: 0.5rem 0.5rem;
        border-radius: 6px;
        border-color: var(--secondary-color);
        border-style: solid;
        background-color: var(--secondary-color);
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        color: var(--white-color);
        transition: all 0.1s ease-in-out;

        a {
            text-decoration: none;
            color: var(--white-color);
            transition: all 0.1s ease-in-out;
        }

        &:hover {
            background-color: var(--white-color);
            color: var(--secondary-color);

            a {
                color: var(--secondary-color);
                border-bottom-style: none
            }
        }
    }
}